import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { AddDeliveryToInHouseConsignment, AddOrderInfoFromDeliveryToInhouseConsignment, DeleteDeliveryInHouseConsignment, UpdateInHouseConsignment } from '../../../queries/deliveryDriverQueries';
import { useParams } from 'react-router-dom';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import { geocodeByAddress, Suggestion } from 'react-places-autocomplete';
import Modal from '../../common/Modal';
import ItemsModal from '../../consignment/Directo/ItemsModal';
import { ReactComponent as AddIcon } from '../../../assets/tracking-app/buttons/add_square.svg';
import { ReactComponent as SearchIcon } from '../../../assets/tracking-app/buttons/Search_alt.svg';
import { ReactComponent as PlusIcon } from '../../../assets/tracking-app/buttons/Chat_plus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/tracking-app/buttons/Close_round.svg';
import { clientUrl } from '../../../config/apiconfig';

type Props = {
  inHouseConsignment: InHouseConsignment;
  setInHouseConsignment: React.Dispatch<React.SetStateAction<InHouseConsignment>>;
  updateAddressSuggestions: (suggestions: Suggestion[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  setShowMapModal: React.Dispatch<React.SetStateAction<boolean>>;
  isUserPermitted?: boolean;
};
const AddDirectoDelivery = ({ inHouseConsignment, setInHouseConsignment, updateAddressSuggestions, inputRef, setShowMapModal, isUserPermitted = true }: Props) => {
  const [deliveryNumber, setDeliveryNumber] = useState<string>();
  const { id } = useParams<{ id: string }>();
  const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
  const [deliveryToShow, setDeliveryToShow] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!deliveryNumber) return;
    if (id === undefined) return;
    if (inHouseConsignment?.directoDeliveryNumbers?.includes(deliveryNumber)) return;
    if (inHouseConsignment.consignmentStatus === ConsignmentStatus.InTransit) return alert("Vedu on juba sõidus. Tellimuse muutmise soovi korral võta palun ühendust Logistikaga");
    AddDeliveryToInHouseConsignment(+id, deliveryNumber)
      .then((response) => {
        setInHouseConsignment({ ...inHouseConsignment, directoDeliveryNumbers: response.data.directoDeliveryNumbers, deliveries: response.data.deliveries });
        setDeliveryNumber(() => '');
      })
      .catch(err => {
        console.log(err);
        // Check if the error response exists and has the 'error' field
        if (err.response && err.response.data && err.response.data.error) {
          const errorMessage = err.response.data.error;

          // Split the error message by newline character to separate the URL and the message
          const [url, message] = errorMessage.split('\n');

          // Display the alert with the message
          alert(`${message}, avan uues aknas?\nURL: ${clientUrl}${url}`);

          // After the user clicks "OK" in the alert, open the URL in a new tab
          var urlToOpen = clientUrl + url;
          console.log(urlToOpen)
          window.open(urlToOpen, '_blank');
        } else {
          // If there is no specific error, display a generic message
          alert("An unknown error occurred");
        }
      });
  };

  const fetchAddressSuggestions = async (address: string) => {
    // Use Google Maps API to fetch address suggestions based on deliveryNumber
    try {
      if (inHouseConsignment.type === 'Export') {
        const results = await geocodeByAddress(address);
        const suggestions: Suggestion[] = results.map((result, index) => ({
          description: result.formatted_address,
          placeId: result.place_id,
          matchedSubstrings: [],
          terms: [],
          type: 'address',
          types: result.types || [],
          id: index.toString(),
          active: false,
          index: index,
          formattedSuggestion: {
            mainText: result.formatted_address, // Adjust this part based on your requirement
            secondaryText: '' // Adjust this part based on your requirement
          }
        }));
        inputRef.current?.focus();
        updateAddressSuggestions(suggestions); // Call the function to update suggestions
        setShowMapModal(true);
      }
    } catch (error) {
      if (inHouseConsignment.type === 'Export') {
        setShowMapModal(true);
      }
      console.error('Error fetching address suggestions:', error);
    }
  };

  const handleAddDeliveryInfoToHeader = async (deliveryNumber: string) => {
    if (!isUserPermitted) return;
    await UpdateInHouseConsignment(inHouseConsignment)
      .then((response: any) => { })
      .catch((error) => {
        console.error(error);
      });

    if (id === undefined) return;
    AddOrderInfoFromDeliveryToInhouseConsignment(+id, deliveryNumber)
      .then((response: AxiosResponse<InHouseConsignment>) => {
        setInHouseConsignment(response.data);
        fetchAddressSuggestions(response.data.deliveryAddressRow1);
        alert(`Lähetuse ${deliveryNumber} info võetud aluseks. Palun kinnitage sihtkoha aadress.`);
      })
      .catch((err) => {
        console.log(err);
        alert(`Ei saanud lähetust ${deliveryNumber} veotellimuse aluseks võtta`);
      });
  };

  const handleRemoveDelivery = (deliveryNumber: string) => {
    if (!isUserPermitted) return;
    if (id === undefined) return;
    DeleteDeliveryInHouseConsignment(+id, deliveryNumber)
      .then((response) => {
        setInHouseConsignment({ ...inHouseConsignment, directoDeliveryNumbers: response.data.directoDeliveryNumbers, deliveries: response.data.deliveries });
      })
      .catch((err) => {
        console.log(err);
        alert('Ei saanud lähetust eemaldada');
      });
  };

  const openItemsModal = (deliveryNumber: string) => {
    setShowItemsModal(true);
    setDeliveryToShow(deliveryNumber);
  };

  return (
    <div className="text-reg" style={{ borderRadius: '5px', padding: '20px', minHeight: '95px', backgroundColor: '#fff' }}>
      <div className="flex-col justify-start items-start">
        {inHouseConsignment.deliveries?.map((x, idx) => (
          <div key={idx} className="flex justiy-between items-center gap-xs">
            <div
              className="flex justiy-between items-center"
              key={idx}
              onClick={() => {
                setShowItemsModal(!showItemsModal);
              }}
              style={{ cursor: 'pointer', gap: "5px", textDecoration: ` ${x.confirmed === "1" ? "line-through" : ""}` }}
            >
              <a className="addedDeliveires__anchor-tag" style={{ color: '#737372' }} target="_blank" rel="noreferrer" href={`https://login.directo.ee/ocra_ets_nord/ladu_l.asp?NUMBER=${x.deliveryNumber}`}>
                {x.deliveryNumber}
              </a>
              <div style={{ fontSize: "13px" }}>
                ({x.deliveryStatus})
              </div>
            </div>
            <div className="flex gap-xs">
              <IconButton style={{ padding: '0' }} onClick={() => openItemsModal(x.deliveryNumber)}>
                <SearchIcon />
              </IconButton>
              <IconButton style={{ padding: '0' }} title="Võta põhjaks" onClick={() => handleAddDeliveryInfoToHeader(x.deliveryNumber)}>
                <PlusIcon />
              </IconButton>
              <IconButton style={{ padding: '0' }} title="Eemalda" onClick={() => handleRemoveDelivery(x.deliveryNumber)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="w-50 flex justify-start items-center" style={{ minWidth: '170px' }}>
          <input readOnly={!isUserPermitted} className="input-addressForm input--tracking-app" type="number" placeholder="Lähetuse nr" value={deliveryNumber} onChange={(e) => setDeliveryNumber(e.target.value)} />
          <IconButton type="submit">
            <AddIcon />
          </IconButton>
        </div>
      </form>
      <Modal show={showItemsModal} onClose={() => setShowItemsModal(!showItemsModal)}>
        <ItemsModal deliveryNumber={deliveryToShow} />
      </Modal>
    </div>
  );
};

export default AddDirectoDelivery;
