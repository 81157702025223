import { ConsignmentCalendar } from "../../Types/ConsignmentCalendar";
import { ConvertDate } from "../common/ConvertDate";

/* export const MergeConsignments = (trRoutes: TransportRoute[], consignments: Consignment[]) => {
    let arr: CalenderObject[] = [];
    trRoutes.forEach((r) => {
        const sortedConsignmentGroup = r.consignment.sort((a, b) => b.consignmentPosition - a.consignmentPosition);


        const calenderObj: CalenderObject = {
            id: r.id,
            isTransportRoute: true,
            consignmentGroup: sortedConsignmentGroup,
            transportRouteTitle: r.title,
            transportRouteStatus: r.status,
        };
        arr.push(calenderObj);

    })
    consignments.forEach((c) => {
        const foundIDs: string[] = arr.flatMap((obj) => obj.consignmentGroup.map((c) => c.id));
        if (!foundIDs.includes(c.id)) {
            const calenderObj: CalenderObject = {
                id: +c.id,
                isTransportRoute: false,
                consignmentGroup: [c],
                transportRouteTitle: "",
                transportRouteStatus: ""
            };
            arr.push(calenderObj);
        }
    });
    return arr;
} */

export const GroupConsignments = (calenderObjectsList: ConsignmentCalendar[]): Record<number, Record<number, Record<number, ConsignmentCalendar[]>>> => {
    const groupedConsignments: Record<number, Record<number, Record<number, ConsignmentCalendar[]>>> = {};

    calenderObjectsList.forEach((calenderObj) => {
        calenderObj.consignmentGroup.forEach((consignment) => {
            const collectionDateStr: string = consignment.collectionDate;
            const collectionDate: Date = ConvertDate(collectionDateStr);
            const weekday: number = collectionDate.getDay();
            const weekNumber: number = GetWeekNumber(collectionDate);

            if (!groupedConsignments[weekNumber]) {
                groupedConsignments[weekNumber] = {};
            }

            if (!groupedConsignments[weekNumber][weekday]) {
                groupedConsignments[weekNumber][weekday] = {};
            }

            const transportRouteId: number = consignment.transportRouteId;
            if (!groupedConsignments[weekNumber][weekday][transportRouteId]) {
                groupedConsignments[weekNumber][weekday][transportRouteId] = [];
            }

            // Check if the consignment already exists in the grouped structure
            const existingConsignment = groupedConsignments[weekNumber][weekday][transportRouteId].find((c) => c.id === calenderObj.id);
            if (!existingConsignment) {
                groupedConsignments[weekNumber][weekday][transportRouteId].push(calenderObj);
            }
        });
    });

    // Sort the week numbers in descending order
    const sortedWeekNumbers: number[] = Object.keys(groupedConsignments).map(Number).sort((a, b) => b - a);

    // Create a new object with sorted week numbers
    const sortedGroupedConsignments: Record<number, Record<number, Record<number, ConsignmentCalendar[]>>> = {};
    sortedWeekNumbers.forEach((weekNumber) => {
        sortedGroupedConsignments[weekNumber] = groupedConsignments[weekNumber];
    });
    return sortedGroupedConsignments;
};

export const GroupConsignments2 = (calenderObjectsList: ConsignmentCalendar[]): Record<number, Record<number, Record<number, ConsignmentCalendar[]>>> => {
    const groupedConsignments: Record<number, Record<number, Record<number, ConsignmentCalendar[]>>> = {};

    calenderObjectsList.forEach((calenderObj) => {
        calenderObj.consignmentGroup.forEach((consignment) => {
            const collectionDateStr: string = consignment.collectionDate;
            const collectionDate: Date = ConvertDate(collectionDateStr);
            const weekday: number = collectionDate.getDay();
            const weekNumber: number = GetWeekNumber(collectionDate);

            if (!groupedConsignments[weekNumber]) {
                groupedConsignments[weekNumber] = {};
            }

            if (!groupedConsignments[weekNumber][weekday]) {
                groupedConsignments[weekNumber][weekday] = {};
            }

            const transportRouteId: number = consignment.transportRouteId;
            if (!groupedConsignments[weekNumber][weekday][transportRouteId]) {
                groupedConsignments[weekNumber][weekday][transportRouteId] = [];
            }

            // Check if the consignment already exists in the grouped structure
            const existingConsignment = groupedConsignments[weekNumber][weekday][transportRouteId].find((c) => c.id === calenderObj.id);
            if (!existingConsignment) {
                groupedConsignments[weekNumber][weekday][transportRouteId].push(calenderObj);
            }
        });
    });

    // Sort the week numbers in descending order
    const sortedWeekNumbers: number[] = Object.keys(groupedConsignments).map(Number).sort((a, b) => b - a);

    // Create a new object with sorted week numbers
    const sortedGroupedConsignments: Record<number, Record<number, Record<number, ConsignmentCalendar[]>>> = {};

    const currentWeekNumber = GetWeekNumber(new Date());

    sortedWeekNumbers.forEach((weekNumber) => {
        if (+weekNumber >= currentWeekNumber) {
            sortedGroupedConsignments[+weekNumber] = groupedConsignments[+weekNumber];
        }
    });
    return sortedGroupedConsignments;

};

export const GetWeekNumber = (date: Date): number => {
    const firstDayOfYear: Date = new Date(date.getFullYear(), 0, 1);
    const millisecondsPerWeek: number = 7 * 24 * 60 * 60 * 1000;
    const weekNumber: number = Math.floor(((date.getTime() - firstDayOfYear.getTime()) / millisecondsPerWeek) + 1);
    return weekNumber;
};

export const ReturnFinnishDayWithDate = (dateString: string): string => {
    dateString = ConvertDate(dateString).toLocaleDateString('et-EE')
    const deliveryDate: Date = ConvertDate(dateString);
    const weekDayNumber = deliveryDate.getDay();
    if (weekDayNumber === 1) {
        return `Ma ${dateString}`
    }
    if (weekDayNumber === 2) {
        return `Ti ${dateString}`
    }
    if (weekDayNumber === 3) {
        return `Ke ${dateString}`
    }
    if (weekDayNumber === 4) {
        return `To ${dateString}`
    }
    if (weekDayNumber === 5) {
        return `Pe ${dateString}`
    }
    if (weekDayNumber === 6) {
        return `La ${dateString}`
    }
    if (weekDayNumber === 0) {
        return `Su ${dateString}`
    }
    return dateString
}

/* export const GetDateFromAndToOld = () => {
    const currentDate = new Date();
    const defaultDateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() + 1);
    const def1 = defaultDateFrom.toISOString().split("T")[0]
    const defaultDateTo = new Date(2024, 11, 31);
    const def2 = defaultDateTo.toISOString().split("T")[0]
    return ({ dateFrom: def1, dateTo: def2 })
} */

export const GetDateFromAndTo = () => {
    const currentDate = new Date();

    // Calculate defaultDateFrom as the Monday of the current week
    const dayOfWeek = currentDate.getDay(); // 0 is Sunday, 6 is Saturday
    const offsetToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    const defaultDateFrom = new Date(currentDate);
    defaultDateFrom.setDate(currentDate.getDate() + offsetToMonday);

    let defaultDateTo: Date;

    // Set dateTo as 3 months in advance if the current month is November or December
    if (currentDate.getMonth() === 10 || currentDate.getMonth() === 11) {
        defaultDateTo = new Date(currentDate);
        defaultDateTo.setMonth(currentDate.getMonth() + 3);
    } else {
        defaultDateTo = new Date(currentDate.getFullYear(), 11, 31);
    }

    const dateFrom = defaultDateFrom.toISOString().split("T")[0];
    const dateTo = defaultDateTo.toISOString().split("T")[0];

    return { dateFrom, dateTo };
};


export const GetCollectionTime = (collectionTimeFrom: string, collectionTimeTo: string) => {
    let timeStringToReturn = ""
    if (collectionTimeFrom !== null) {
        timeStringToReturn += removeSecondsFromTimeString(collectionTimeFrom);
        if (collectionTimeTo !== null) {
            timeStringToReturn += `-${removeSecondsFromTimeString(collectionTimeTo)}`
        }
    }
    return timeStringToReturn;
}

const removeSecondsFromTimeString = (timeString: string) => {
    try {
        // Split the time string by ":" to get hours, minutes, and seconds.
        const [hours, minutes, seconds] = timeString.split(':');

        // Remove the seconds part from the time string.
        const formattedTimeString = `${hours}:${minutes}`;

        return formattedTimeString;
    } catch (error) {
        return timeString;
    }
}