import React from 'react';
import '../../styles/modal.css'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

type ModalProps = {
    show: boolean;
    onClose: () => void;
    children: React.ReactNode;
    maxWidth?: string
    title?: string
};

const Modal: React.FC<ModalProps> = ({ show, onClose, children, maxWidth = "700px", title }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal" style={{ overflowY: "auto" }}>
            <div className="modal-content" style={{ maxWidth: maxWidth }}>
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginBottom: "20px" }}>
                    {title && <div style={{ marginTop: "10px", fontSize: "18px" }}>{title}</div>}
                    <IconButton className="close-icon" style={{ background: "none", color: "gray", marginLeft: "auto" }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                {children}

            </div>
        </div>
    );
};

export default Modal;
