import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { SearchOrderWithOrderNumber } from '../../../queries/directo-queries';
import { AddOrderToInHouseConsignment, DeleteOrderInHouseConsignment } from '../../../queries/deliveryDriverQueries';
import { useParams } from 'react-router-dom';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import { Suggestion } from 'react-places-autocomplete';
import Modal from '../../common/Modal';
import OrderDetailsModal from '../../consignment/Directo/OrderDetailsModal';
import { ReactComponent as AddIcon } from '../../../assets/tracking-app/buttons/add_square.svg';
import { ReactComponent as SearchIcon } from '../../../assets/tracking-app/buttons/Search_alt.svg';
import { ReactComponent as CloseIcon } from '../../../assets/tracking-app/buttons/Close_round.svg';

type Props = {
  inHouseConsignment: InHouseConsignment;
  setInHouseConsignment: React.Dispatch<React.SetStateAction<InHouseConsignment>>;
  updateAddressSuggestions: (suggestions: Suggestion[]) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  setShowMapModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddDirectoSalesOrder = ({ inHouseConsignment, setInHouseConsignment, updateAddressSuggestions, inputRef, setShowMapModal }: Props) => {
  const [orderNumber, setOrderNumber] = useState<string>();
  const { id } = useParams<{ id: string }>();
  const [showItemsModal, setShowItemsModal] = useState<boolean>(false);
  const [orderToShow, setOrderToShow] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!orderNumber) return;
    if (id === undefined) return;
    if (inHouseConsignment?.directoOrderNumbers?.includes(orderNumber)) return;
    if (inHouseConsignment.consignmentStatus === ConsignmentStatus.InTransit) return alert("Vedu on juba sõidus. Tellimuse muutmise soovi korral võta palun ühendust Logistikaga");
    try {
      await SearchOrderWithOrderNumber(orderNumber);
      AddOrderToInHouseConsignment(+id, orderNumber)
        .then((response) => {
          setInHouseConsignment({ ...inHouseConsignment, directoOrderNumbers: response.data.directoOrderNumbers, deliveries: response.data.deliveries });
          setOrderNumber(() => '');
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data);
        });
    } catch (error) {
      alert('Ei leitud müügitellimust');
    }
  };

  const openItemsModal = (orderNumber: string) => {
    setShowItemsModal(true);
    setOrderToShow(orderNumber);
  };

  const handleRemoveSalesOrder = (orderNumber: string) => {
    if (id === undefined) return;
    DeleteOrderInHouseConsignment(+id, orderNumber)
      .then((response: AxiosResponse<InHouseConsignment>) => {
        setInHouseConsignment({ ...inHouseConsignment, directoOrderNumbers: response.data.directoOrderNumbers, deliveries: response.data.deliveries });
      })
      .catch((err) => {
        console.log(err);
        alert('Ei saanud müügitellimust eemaldada');
      });
  };

  return (
    <div className="text-reg" style={{ borderRadius: '5px', padding: '20px', minHeight: '95px', backgroundColor: '#fff' }}>
      <div className="flex-col justify-start items-start">
        {inHouseConsignment.directoOrderNumbers?.map((x, idx) => (
          <div key={idx} className="flex justify-between items-center gap-xs">
            <a className="addedDeliveires__anchor-tag" style={{ color: '#737372' }} target="_blank" rel="noreferrer" href={`https://login.directo.ee/ocra_ets_nord/tell_tellimus.asp?NUMBER=${x}`}>
              {x}
            </a>
            <div className="flex gap-xs">
              <IconButton style={{ padding: '0' }} onClick={() => openItemsModal(x)}>
                <SearchIcon />
              </IconButton>
              <IconButton style={{ padding: '0' }} title="Eemalda" onClick={() => handleRemoveSalesOrder(x)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="w-50 flex justify-center items-center" style={{ minWidth: '170px' }}>
          <input className="input-addressForm input--tracking-app" type="number" placeholder="Tellimuse nr" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />
          <IconButton type="submit">
            <AddIcon />
          </IconButton>
        </div>
      </form>
      <Modal show={showItemsModal} onClose={() => setShowItemsModal(false)}>
        <OrderDetailsModal setInHouseConsignment={setInHouseConsignment} inHouseConsignment={inHouseConsignment} orderNumber={orderToShow} setShowModal={setShowItemsModal} />
      </Modal>
    </div>
  );
};

export default AddDirectoSalesOrder;
