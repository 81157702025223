import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { useDataContext } from '../../context/DataContext'
import { CargosonPriceList } from '../../Types/cargoson/CargosonPriceList'
import { CargosonQueryResponse2 } from '../../Types/cargoson/CargosonQueryResponse2'
import { TransportRoute } from '../../Types/TransportRoute'
import ReturnToCargoson from '../cargoson/ReturnToCargoson'
import ErrorService from '../common/errors/ErrorService'
import ReturnToDirectoTransportRoute from '../transport-route/ReturnToDirectoTransportRoute'
import '../../styles/consignment-options.css'
import { useNavigate } from 'react-router-dom'
import EmailPreviewModal from '../email/EmailPreviewModal'
import { useTranslation } from 'react-i18next'
import ReturnToDirecto from './ReturnToDirecto'
import { GetPrintoutFromDirecto, SendMultipleQueriesToCargoson, GetFreightListPriceCargoson, SaveConsignmentList, SaveConsignmentToDirecto } from '../../queries/queries'
import { AddConsignmentListToTransportRoute, SaveTransportRoute, SaveTransportRouteToDirecto, UpdateCustomerReference } from '../../queries/transport-route-queries'

type Props = {
    listIndex: number,
}
const ConsignmentOptions = ({ listIndex }: Props) => {
    const [returnCargosonId, setReturnCargosonId] = useState<string>("");
    const { setConsignmentList, consignmentList, transportRouteId, setCargosonQueryResponse, setCargosonPricelist, setErrors, setIsLoading, returnDirectoCode, setReturnDirectoCode, updateConsignmentData, transportRouteData, setTransportRouteData } = useDataContext();
    const [isError, setIsError] = useState<boolean>(false);
    const [errorPropName, setErrorPropName] = useState<string>("");
    const { t } = useTranslation()
    const navigate = useNavigate();

    //for requesting printout from directo
    const exportCMRPrintoutNumber = 1480
    const importCMRPrintoutNumber = 1601

    const checkDirectoCode = (): boolean => {
        if (consignmentList[listIndex].directoCode === null || consignmentList[listIndex].directoCode === undefined) {
            return false;
        }
        if (returnDirectoCode === null || returnDirectoCode === undefined) {
            return false;
        }
        else {
            return true;
        }
    }

    if (isError) {
        setTimeout(() => {
            setIsError(false);
        }, 4500);
    }

    const handleDownloadCMR = () => {
        for (let i = 0; i < consignmentList.length; i++) {
            const directoCode = consignmentList[i].directoCode;
            var CMRPrintoutNumber = 0;
            if (consignmentList[i].type === "Import") {
                CMRPrintoutNumber = importCMRPrintoutNumber
            }
            if (consignmentList[i].type === "Export") {
                CMRPrintoutNumber = exportCMRPrintoutNumber
            }
            GetPrintoutFromDirecto(directoCode, CMRPrintoutNumber).then((response) => {
                let responseHtml = response.data;
                var blob = new Blob([responseHtml], { type: 'application/vnd.ms-excel' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                if (transportRouteId !== "") {
                    link.download = `CMR-veoring-${transportRouteData.directoCode}.xls`
                }
                else {
                    link.download = `CMR-${directoCode}.xls`
                }
                link.click();
            }).catch((err) => {
                console.log(err);
            });

        }
    }

    const validateCargosonFields = (): boolean => {

        for (let i = 0; i < consignmentList.length; i++) {
            const consignmentToCheck = consignmentList[i].cargosonData;
            if (consignmentToCheck.pickups[0]?.rows_attributes.length === 0) {
                if (i === 0) {
                    setErrorPropName(`Veotellimuse pakkeüksused peavad olemad täidetud`);
                }
                else {
                    setErrorPropName(`${i + 1}. Veotellimuse pakkeüksused peavad olemad täidetud`);
                }
                setIsError(true);
                return false;
            }


            if (
                (consignmentToCheck.pickups[0]?.collection_loading_area === null && consignmentToCheck.pickups[0]?.collection_company_name !== null && consignmentToCheck.pickups[0]?.collection_company_name?.toUpperCase() === "ETS NORD") ||
                (consignmentToCheck.pickups[0]?.collection_loading_area === undefined && consignmentToCheck.pickups[0]?.collection_company_name !== null && consignmentToCheck.pickups[0]?.collection_company_name?.toUpperCase() === "ETS NORD") ||
                (consignmentToCheck.pickups[0]?.collection_loading_area === "" && consignmentToCheck.pickups[0]?.collection_company_name !== null && consignmentToCheck.pickups[0]?.collection_company_name?.toUpperCase() === "ETS NORD")
            ) {

                if (i === 0) {
                    setErrorPropName(`Veotellimuse pealelaadimiskoha laadimisala peab olema täidetud`);
                }
                else {
                    setErrorPropName(`${i + 1}. Veotellimuse pealelaadimiskoha laadimisala peab olema täidetud`);
                }
                setIsError(true);
                return false;
            }
            if ((consignmentToCheck.delivery_loading_area === null && consignmentToCheck.delivery_company_name !== null && consignmentToCheck.delivery_company_name.toUpperCase() === "ETS NORD") ||
                (consignmentToCheck.delivery_loading_area === undefined && consignmentToCheck.delivery_company_name.toUpperCase() === "ETS NORD") ||
                (consignmentToCheck.delivery_loading_area === "" && consignmentToCheck.delivery_company_name.toUpperCase() === "ETS NORD")) {
                if (i === 0) {
                    setErrorPropName(`Veotellimuse mahalaadimiskoha laadimisala peab olema täidetud`);
                }
                else {
                    setErrorPropName(`${i + 1}. Veotellimuse mahalaadimiskoha laadimisala peab olema täidetud`);
                }
                setIsError(true);
                return false;
            }

            if (consignmentToCheck.delivery_date === null || consignmentToCheck.delivery_date === undefined) {
                console.log(consignmentToCheck.delivery_date)
                if (i === 0) {
                    setErrorPropName(`Mahalaadimise kuupäev ei tohi olla tühi`);
                }
                else {
                    setErrorPropName(`${i + 1}.Mahalaadimise kuupäev ei tohi olla tühi`);
                }
                setIsError(true);
                return false;
            }

            if (consignmentToCheck.pickups[0]?.collection_date === null || consignmentToCheck.pickups[0]?.collection_date === undefined) {
                if (i === 0) {
                    setErrorPropName(`Pealelaadimise kuupäev ei tohi olla tühi`);
                }
                else {
                    setErrorPropName(`${i + 1}.Pealelaadimise kuupäev ei tohi olla tühi`);
                }
                setIsError(true);
                return false;
            }

            if (consignmentList[i].carrierCode === null) {
                if (window.confirm(`Vedaja on valimata, saada ikkagi?`)) {
                    setIsError(false);
                    return true
                }
            }

        }
        return true;
    }

    const validateCargosonCarrier = (): boolean => {
        for (let i = 0; i < consignmentList.length; i++) {
            const consignmentToCheck = consignmentList[i].cargosonData;
            const isPriceValid = consignmentToCheck?.total_price !== 0 && consignmentToCheck?.total_price !== undefined && consignmentToCheck?.total_price !== null && consignmentToCheck?.total_price.toString() !== "";

            //service id is used to determine if carrier is in Cargoson
            const cargosonCarrier = consignmentToCheck?.serviceId !== null;

            //if carrier is not in cargoson and price is inserted then user cannot send transport order to Cargoson
            if (!cargosonCarrier && isPriceValid) {
                if (i === 0) {
                    setErrorPropName(`Kui hind on sisestatud peab vedaja olema Cargosonis`);
                }
                else {
                    setErrorPropName(`${i + 1}. Cargosoni vedaja on valimata`);
                }
                setIsError(true);
                return false;
            }
        }
        return true
    }

    const handleQuerySubmit = () => {

        if (!validateCargosonFields()) {
            return;
        }
        if (!validateCargosonCarrier()) {
            return;
        }

        setIsLoading(true);
        SendMultipleQueriesToCargoson(consignmentList).then((response: AxiosResponse<CargosonQueryResponse2[]>) => {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].query_status === "failed") {
                    setCargosonQueryResponse(response.data)
                    return;
                }
                if (response.data[i].query_status === "created") {

                    if (consignmentList[listIndex].cargosonData.serviceId !== null && consignmentList[listIndex].cargosonData.serviceId !== undefined) {
                        updateConsignmentData(listIndex, "consignmentStatus", "TransportOrdered")
                    }
                    setReturnCargosonId(response.data[i].id.toString())
                    setErrors([])
                }
            }
        }).catch(err => { console.log(err) })
            .finally(() => { setIsLoading(false) })
    };

    const handleFreightPricesSubmit = () => {
        if (!validateCargosonFields()) {
            return;
        }
        setIsLoading(true);
        GetFreightListPriceCargoson(consignmentList[0].cargosonData, consignmentList[0].cargosonData.serviceId).then((response: AxiosResponse<CargosonPriceList[]>) => {
            console.log(response.data)
            if (response.data === null) return
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].errors !== null) {
                    alert(response.data[i].errors)
                    return;
                }
            }
            setCargosonPricelist(response.data)
        })
            .catch(err => console.log(err))
            .finally(() => { setIsLoading(false) });
    }


    const handleSaveConsignmentListToDirecto = () => {
        if (!validateCargosonFields()) {
            return;
        }

        setIsLoading(true)
        SaveConsignmentList(consignmentList).then(() => {
            SaveConsignmentToDirecto(consignmentList).then((response) => {
                setReturnDirectoCode(response.data.consignmentDirectoCode)

                var tempArr = consignmentList.map((item, idx) => idx === listIndex ? {
                    ...item,
                    "directoCode": response.data.consignmentDirectoCode,
                    cargosonData: { ...item.cargosonData, "customer_reference": response.data.consignmentDirectoCode }
                } : item);

                setConsignmentList(tempArr);

                alert("Saadetud Directosse")
            }).catch(err => {
                console.log(err)
                alert(err.response.status + ": " + err.response.data)
            });
        }).catch(err => console.log(err));

        setTimeout(() => {
            setIsLoading(false)
        }, 1500);
    }

    const handleSavetoDirectoNew = async () => {
        // Validate fields before proceeding
        if (!validateCargosonFields()) {
            return;
        }

        try {
            // Set loading state
            setIsLoading(true);

            // Step 1: Save the consignment list
            await SaveConsignmentList(consignmentList);

            // Step 2: Save consignment to Directo and get response
            const response = await SaveConsignmentToDirecto(consignmentList);

            // Extract the returned Directo code
            const directoCode = response.data.consignmentDirectoCode;

            // Step 3: Update the consignment list with the Directo code
            const updatedList = consignmentList.map((item, idx) =>
                idx === listIndex
                    ? {
                        ...item,
                        directoCode,
                        cargosonData: {
                            ...item.cargosonData,
                            customer_reference: directoCode,
                        },
                    }
                    : item
            );

            // Update the state with the modified list
            setConsignmentList(updatedList);
            setReturnDirectoCode(directoCode);

            // Notify user of success
            alert("Saadetud Directosse");
        } catch (err: any) {
            // Log the error and notify the user
            console.error(err);

            if (err.response) {
                alert(`${err.response.status}: ${err.response.data}`);
            } else {
                alert("An unexpected error occurred.");
            }
        } finally {
            // Clear loading state regardless of success or failure
            setIsLoading(false);
        }
    };


    const handleSaveConsignmentList = () => {
        setIsLoading(true)
        SaveConsignmentList(consignmentList).then(() => {
            alert("Salvestatud")
        }).catch(err => console.log(err));

        setTimeout(() => {
            setIsLoading(false)
        }, 1500);
    }

    const handleSaveTransportRouteToDirecto = async () => {
        if (!validateCargosonFields()) {
            return;
        }
        setIsLoading(true)
        var tempList: TransportRoute = { ...transportRouteData, consignment: consignmentList }

        await SaveConsignmentList(consignmentList);
        await AddConsignmentListToTransportRoute(+transportRouteId, consignmentList)
        await SaveTransportRoute(transportRouteData)
        await SaveTransportRouteToDirecto(tempList).then((response: AxiosResponse<TransportRoute>) => {
            alert("Saadetud Directosse")
            setTransportRouteData(response.data)
            setConsignmentList(response.data.consignment)

            setReturnDirectoCode(response.data.directoCode)
            UpdateCustomerReference(transportRouteData.id, response.data.directoCode)

        }).catch(err => console.log(err));

        navigate(("/transport-route/details/" + transportRouteData.id))

        setIsLoading(false)
    }

    const handleSaveTransportRoute = async () => {
        setIsLoading(true)

        await SaveConsignmentList(consignmentList)
        await AddConsignmentListToTransportRoute(+transportRouteId, consignmentList)
        await SaveTransportRoute(transportRouteData)
        alert("Salvestatud")
        setTimeout(() => {
            setIsLoading(false)
        }, 1500);
    }

    return (
        <div className='consignment-options--footer'>
            <ErrorService message={errorPropName} error={isError} setError={setIsError} />

            {consignmentList[listIndex].cargosonData.id && (<div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                <EmailPreviewModal title={t('Emaili eelvaade')} />
                <ReturnToCargoson returnId={returnCargosonId} listIndex={listIndex} />
                {consignmentList[listIndex].directoCode && <button className="consignment-options--button" style={{ backgroundColor: "#F6CC45", color: "#000" }} onClick={handleQuerySubmit}>Saada Cargosoni</button>}

                <button className="consignment-options--button" style={{ backgroundColor: "#F6CC45", color: "#000" }} onClick={handleFreightPricesSubmit}>Vaata hindu</button>

                {(!transportRouteId || transportRouteId === "") &&
                    <>
                        <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleSaveConsignmentListToDirecto}>Edasta Directosse</button>
                        <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleSaveConsignmentList}>Salvesta</button>
                    </>
                }
                {transportRouteId &&
                    <>
                        <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleSaveTransportRouteToDirecto}>Edasta Directosse</button>
                        <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleSaveTransportRoute}>Salvesta</button>
                    </>
                }

                {!transportRouteId && <ReturnToDirecto listIndex={listIndex} />}

                {transportRouteId && <ReturnToDirectoTransportRoute returnId={returnDirectoCode} />}

                {!transportRouteId && checkDirectoCode() &&
                    <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleDownloadCMR}>
                        CMR
                    </button>}
                {transportRouteId && checkDirectoCode() &&
                    <button className="consignment-options--button" style={{ backgroundColor: "#1522a0" }} onClick={handleDownloadCMR}>
                        CMR
                    </button>}

            </div>)}

        </div>
    )
}

export default ConsignmentOptions