import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteInHouseConsignment, GetActiveDeliveryDrivers, GetDeliveryDriverLocation, GetInHouseConsignment, GetInHouseConsignmentDirectoCode, SendInHouseConsignmentToDirecto, UpdateInHouseConsignment } from '../../../queries/deliveryDriverQueries';
import { InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import AddDirectoDelivery from '../../../components/tracking-app/logistician/AddDirectoDelivery';
import DateTimeCalendar from '../../../components/common/DateTimeCalendar';
import { ConvertDate, ConvertTime } from '../../../components/common/ConvertDate';
import { DeliveryDriver } from '../../../Types/delivery-app/DeliveryDriver';
import { Suggestion } from 'react-places-autocomplete';
import SelectPlaceInputModal from '../../../components/tracking-app/logistician/SelectPlaceInputModal';
import { useDataContext } from '../../../context/DataContext';
import Loading from '../../../components/common/Loading';
import AddDirectoSalesOrder from '../../../components/tracking-app/logistician/AddDirectoSalesOrder';
import '../../../styles/tracking-app/delivery-page.css';
import '../../../styles/tracking-app/delivery-details.css';
import TrackingDeliveryDriver from '../../../components/tracking-app/TrackingDeliveryDriver';
import { ReactComponent as NavigateToDirectoIcon } from '../../../assets/tracking-app/buttons/navigate-directo.svg';
import { ReactComponent as SendToDirectoIcon } from '../../../assets/tracking-app/buttons/send-directo-button.svg';
import { ReactComponent as AddIcon } from '../../../assets/tracking-app/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/tracking-app/buttons/remove-button.svg';
import { ReactComponent as SaveIcon } from '../../../assets/tracking-app/buttons/save-button.svg';
import { ReactComponent as UncheckedBox } from '../../../assets/tracking-app/buttons/checkbox.svg';
import { ReactComponent as CheckedBox } from '../../../assets/tracking-app/buttons/checkbox-checked.svg';
import useWindowDimensions from '../../../components/common/useWindowsDimensions';
import AddPurchaseOrder from '../../../components/tracking-app/logistician/AddPurchaseOrder';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';

const DeliveryDetails = () => {
  const { t } = useTranslation();
  const { permissions } = useCustomer();
  const { id, directoCode } = useParams<{ id: string, directoCode: string }>();
  const navigate = useNavigate();
  const [deliveryData, setDeliveryData] = useState<InHouseConsignment>({
    deliveryLatitude: 0,
    deliveryLongitude: 0
  } as InHouseConsignment);
  const [deliveryVehicles, setDeliveryVehicles] = useState<DeliveryDriver[]>([]);
  const [collectionTimeFrom, setCollectionTimeFrom] = useState<Date | null>(deliveryData.collectionTimeFrom ? ConvertTime(deliveryData.collectionTimeFrom) : new Date());
  const [deliveryTimeTo, setDeliveryTimeTo] = useState<Date | null>(deliveryData.deliveryTimeTo ? ConvertTime(deliveryData.deliveryTimeTo) : new Date());
  const [deliveryTimeFrom, setDeliveryTimeFrom] = useState<Date | null>(deliveryData.deliveryTimeFrom ? ConvertTime(deliveryData.deliveryTimeFrom) : new Date());
  const [addressSuggestions, setAddressSuggestions] = useState<Suggestion[]>([]);
  const [showMapModalDelivery, setShowMapModalDelivery] = useState(false);
  const [showMapModalCollection, setShowMapModalCollection] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isLoading, setIsLoading } = useDataContext();
  const { width } = useWindowDimensions();
  const mapWidth = width > 800 ? width / 2 : width - 25;
  const [deliveryDriverLocation, setDeliveryDriverLocation] = useState<GeolocationPosition | null>(null);
  const [deliveryLocation, setDeliveryLocation] = useState<GeolocationPosition | null>(null);
  const [collectionLoaction, setCollectionLocation] = useState<GeolocationPosition | null>(null);
  const screenHeight = window.screen.height - window.screen.height * 0.1;
  const consignmentDate = new Date(deliveryData.consignmentDate);
  const formattedDate = consignmentDate.toLocaleDateString('et-EE');  // Date part
  const formattedTime = consignmentDate.toLocaleTimeString('et-EE');  // Time part

  const updateAddressSuggestions = (suggestions: Suggestion[]) => {
    setAddressSuggestions(suggestions);
  };

  const handleFetchDeliveryVehicles = async () => {
    await GetActiveDeliveryDrivers().then((response) => {
      setDeliveryVehicles(response.data);
    });
  };

  const handleDynamicChange = (e: any) => {
    var tempArr: InHouseConsignment = {
      ...deliveryData,
      [e.target.name]: e.target.value
    };
    setDeliveryData(tempArr);
  };

  const handleLoadData = () => {

    if (id !== undefined) {
      setIsLoading(true);
      GetInHouseConsignment(+id)
        .then((response: AxiosResponse<InHouseConsignment>) => {
          setDeliveryData(response.data);
          setCollectionTimeFrom(response.data.collectionTimeTo ? ConvertTime(response.data.collectionTimeTo) : null);
          setDeliveryTimeFrom(response.data.deliveryTimeFrom ? ConvertTime(response.data.deliveryTimeFrom) : null);
          setDeliveryTimeTo(response.data.deliveryTimeTo ? ConvertTime(response.data.deliveryTimeTo) : null);
          const deliveryGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryLatitude,
              longitude: response.data.deliveryLongitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: Date.now()
          };
          const collectionGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.collectionLatitude,
              longitude: response.data.collectionLongitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: Date.now()
          };
          const driverGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryDriver.latitude,
              longitude: response.data.deliveryDriver.longitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: response.data.deliveryDriver.locationUpdateTime
          };
          setCollectionLocation(collectionGeolocationPosition);
          setDeliveryLocation(deliveryGeolocationPosition);
          setDeliveryDriverLocation(driverGeolocationPosition);
        })
        .catch((error) => {
          console.error(error);
        });
      setIsLoading(false);
    }
    if (directoCode !== undefined) {
      setIsLoading(true);
      GetInHouseConsignmentDirectoCode(directoCode)
        .then((response: AxiosResponse<InHouseConsignment>) => {
          setDeliveryData(response.data);
          setCollectionTimeFrom(response.data.collectionTimeTo ? ConvertTime(response.data.collectionTimeTo) : null);
          setDeliveryTimeFrom(response.data.deliveryTimeFrom ? ConvertTime(response.data.deliveryTimeFrom) : null);
          setDeliveryTimeTo(response.data.deliveryTimeTo ? ConvertTime(response.data.deliveryTimeTo) : null);
          const deliveryGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryLatitude,
              longitude: response.data.deliveryLongitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: Date.now()
          };
          const collectionGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.collectionLatitude,
              longitude: response.data.collectionLongitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: Date.now()
          };
          const driverGeolocationPosition: GeolocationPosition = {
            coords: {
              latitude: response.data.deliveryDriver.latitude,
              longitude: response.data.deliveryDriver.longitude,
              altitude: null,
              accuracy: 0,
              altitudeAccuracy: null,
              heading: null,
              speed: null
            },
            timestamp: response.data.deliveryDriver.locationUpdateTime
          };
          setCollectionLocation(collectionGeolocationPosition);
          setDeliveryLocation(deliveryGeolocationPosition);
          setDeliveryDriverLocation(driverGeolocationPosition);
        })
        .catch((error) => {
          console.error(error);
        });
      setIsLoading(false);
    }
  };

  const handleDeleteInHouseConsignment = () => {
    if (id === undefined) return;
    if (window.confirm('Kustuta veotellimus?')) {
      DeleteInHouseConsignment(deliveryData.id)
        .then((response: any) => {
          setDeliveryData({} as InHouseConsignment);
          navigate('/delivery');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleDeliveryDate = (date: Date) => {
    var tempArr: InHouseConsignment = {
      ...deliveryData,
      deliveryDate: date?.toLocaleString('et-EE', { year: 'numeric', month: '2-digit', day: '2-digit' })
    };
    setDeliveryData(tempArr);
  };

  const handleDeliveryTimeFrom = (date: Date) => {
    setDeliveryTimeFrom(date);
    var tempArr: InHouseConsignment = {
      ...deliveryData,
      deliveryTimeFrom: date?.toLocaleTimeString('et-EE', { hour12: false, hour: '2-digit', minute: '2-digit' })
    };
    setDeliveryData(tempArr);
  };

  const handleDeliveryTimeTo = (date: Date) => {
    setDeliveryTimeTo(date);

    var tempArr: InHouseConsignment = {
      ...deliveryData,
      deliveryTimeTo: date?.toLocaleTimeString('et-EE', { hour12: false, hour: '2-digit', minute: '2-digit' })
    };
    setDeliveryData(tempArr);
  };

  const handleConsignmentCategory = (category: string) => {
    if (category === 'Eksport') {
      const tempArr: InHouseConsignment = {
        ...deliveryData,
        type: 'Export',
        deliveryAddressRow1: '',
        deliveryCity: '',
        deliveryCountry: '',
        deliveryPostcode: '',
        deliveryCompanyName: '',
        deliveryLatitude: 0,
        deliveryLongitude: 0,

        collectionAddressRow1: 'ETS NORD, Peterburi tee 53g, Tallinn, Estonia',
        collectionCity: 'Tallinn',
        collectionCountry: 'ET',
        collectionPostcode: '11415',
        collectionCompanyName: 'ETS NORD',
        collectionLatitude: 59.4288979,
        collectionLongitude: 24.8181061
      };
      setDeliveryData(tempArr);
      return;
    }
    if (category === 'Import') {
      const tempArr: InHouseConsignment = {
        ...deliveryData,
        type: 'Import',
        deliveryLatitude: 59.4288979,
        deliveryLongitude: 24.8181061,
        deliveryAddressRow1: 'ETS NORD, Peterburi tee 53g, Tallinn, Estonia',
        deliveryCity: 'Tallinn',
        deliveryCountry: 'ET',
        deliveryPostcode: '11415',
        deliveryCompanyName: 'ETS NORD',
        collectionLatitude: 0,
        collectionLongitude: 0,
        collectionAddressRow1: ''
      };
      setDeliveryData(tempArr);
      return;
    }
  };

  const handleDeliveryAddressData = (address: string, latitude: number, longitude: number) => {
    const tempArr: InHouseConsignment = {
      ...deliveryData,
      deliveryAddressRow1: address,
      deliveryLatitude: latitude,
      deliveryLongitude: longitude
    };
    setDeliveryLocation({
      coords: {
        latitude: latitude,
        longitude: longitude,
        altitude: null,
        accuracy: 0,
        altitudeAccuracy: null,
        heading: null,
        speed: null
      },
      timestamp: Date.now()
    });
    setDeliveryData(tempArr);
  };

  const handleCollectionAddressData = (address: string, latitude: number, longitude: number) => {
    const tempArr: InHouseConsignment = {
      ...deliveryData,
      collectionAddressRow1: address,
      collectionLatitude: latitude,
      collectionLongitude: longitude
    };
    setCollectionLocation({
      coords: {
        latitude: latitude,
        longitude: longitude,
        altitude: null,
        accuracy: 0,
        altitudeAccuracy: null,
        heading: null,
        speed: null
      },
      timestamp: Date.now()
    });
    setDeliveryData(tempArr);
  };

  const handleSaveToDirecto = async () => {
    await UpdateInHouseConsignment(deliveryData);
    var response = await SendInHouseConsignmentToDirecto(deliveryData);
    setDeliveryData({ ...deliveryData, directoCode: response.data.consignmentDirectoCode });
    alert('Salvestatud');
  };

  const handleSaveInHouseConsignment = () => {
    UpdateInHouseConsignment(deliveryData)
      .then((response: any) => {
        alert('Salvestatud');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const goToDirecto = (directoCode: string) => {
    if (directoCode) {
      window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
    }
  };

  const fetchDeliveryDriverLocation = async (driverId: number) => {
    if (driverId === undefined) return;
    GetDeliveryDriverLocation(driverId).then((response: any) => {
      const driverGeolocationPosition: GeolocationPosition = {
        coords: {
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          altitude: null,
          accuracy: 0,
          altitudeAccuracy: null,
          heading: null,
          speed: null
        },
        timestamp: response.data.locationUpdateTime
      };

      setDeliveryDriverLocation(driverGeolocationPosition);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleFetchDeliveryVehicles();
        handleLoadData();
        await fetchDeliveryDriverLocation(deliveryData.deliveryDriverId);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!permissions.includes("PInHouseDriverManager")) {
    return <Navigate to="/unauthorized" />
  }

  return (
    <div className="delivery-details-wrapper">
      <header className="delivery-driver--header" style={{ marginTop: '0', height: '209px', width: `${width >= 1080 ? '50%' : '100%'}`, marginLeft: '0' }}>
        <nav className="flex justify-between items-center" style={{ height: '55%' }}>
          <div
            className="flex flex-row justify-between items-center"
            style={{
              width: '100%',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              paddingRight: '0'
            }}
          >

            <div className="flex justify-center" style={{ marginRight: '7%' }}>
              <IconButton onClick={handleSaveToDirecto} style={{ padding: '0' }}>
                <SaveIcon />
              </IconButton>
              {/*  <IconButton onClick={handleSaveInHouseConsignment} style={{ padding: '0' }}>
                <SaveIcon />
              </IconButton> */}

              <IconButton onClick={handleDeleteInHouseConsignment} style={{ padding: '0' }}>
                <DeleteIcon />
              </IconButton>
            </div>

            <div className="flex justify-center">
              {/* <IconButton onClick={handleSaveToDirecto} style={{ padding: '0' }}>
                <SendToDirectoIcon />
              </IconButton> */}
              {
                process.env.NODE_ENV === "development" &&
                <IconButton onClick={handleSaveInHouseConsignment} style={{ padding: '0' }}>
                  <div style={{ width: "70px", display: "flex", justifyContent: "center", alignItems: "center", height: "38px", fontSize: "12px", color: "#fff", border: "1px solid #fff" }}>Salvesta mustand</div>
                </IconButton>
              }
              <IconButton disabled={deliveryData.directoCode === null} onClick={() => goToDirecto(deliveryData.directoCode)} style={{ padding: '0', opacity: `${deliveryData.directoCode ? "1" : "0.5"}` }}>
                <NavigateToDirectoIcon />
              </IconButton>
            </div>
          </div>
        </nav>
      </header>

      <div className="delivery-page--info-map-container" style={{ gap: '0' }}>
        <div className="flex justify-center" style={{ width: `${width >= 1080 ? '50%' : '100%'}` }}>
          <div className="delivery-details--info-block">
            <div className="flex justify-between  text-reg">
              <div className="flex gap-xs" style={{ marginBottom: '1%', width: '50%' }}>
                <div className="flex items-center justify-start">
                  <IconButton onClick={() => handleConsignmentCategory('Eksport')}>{deliveryData.type === 'Export' ? <CheckedBox /> : <UncheckedBox />}</IconButton>
                  Eksport
                </div>
                <div className="flex items-center justify-start">
                  <IconButton onClick={() => handleConsignmentCategory('Import')}>{deliveryData.type === 'Import' ? <CheckedBox /> : <UncheckedBox />}</IconButton>
                  Import
                </div>
              </div>
              <div className="text-reg w-25">
                Staatus
                <select className="input-addressForm text-reg" style={{ color: '#e10008' }} name="consignmentStatus" id="select-status" value={deliveryData.consignmentStatus || ''} onChange={(e: any) => handleDynamicChange(e)}>
                  <option value="0">Tühistatud</option>
                  <option value="1">Planeeritud</option>
                  <option value="2">Sõidus</option>
                  <option value="3">Ootab klienti</option>
                  <option value="4">Üle antud</option>
                  <option value="5">Peale laetud</option>
                  <option value="6">Allkirjastamata</option>
                  <option value="7">Lõpetatud</option>
                  <option value="8">Koostatud</option>
                  <option value="9">Koostamata</option>
                </select>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-reg" style={{ width: '65%' }}>
                Broneeritud sõidu aeg
                <div style={{ display: 'flex', gap: '10px' }}>
                  <div style={{ width: '40%' }}>
                    <DateTimeCalendar handleChange={handleDeliveryDate} currentDate={deliveryData.deliveryDate ? ConvertDate(deliveryData.deliveryDate) : new Date()} />
                  </div>
                  <div style={{ width: '30%' }}>
                    <DateTimeCalendar handleChange={handleDeliveryTimeFrom} currentDate={deliveryTimeFrom} showTimeSelectOnly={true} placeHolder={t('Alates')} />
                  </div>
                  <div style={{ width: '30%' }}>
                    <DateTimeCalendar handleChange={handleDeliveryTimeTo} currentDate={deliveryTimeTo} showTimeSelectOnly={true} placeHolder={t('Kuni')} />
                  </div>
                </div>
              </div>
              <div className="text-reg w-25">
                Auto:
                <select className="input-addressForm text-reg" name="deliveryDriverId" value={deliveryData.deliveryDriverId ?? ''} onChange={(e: any) => handleDynamicChange(e)}>
                  <option value="">Valige auto</option>
                  {deliveryVehicles.map((x, idx) => (
                    <option key={idx} value={x.id}>
                      {x.carName} - {x.carLicenseNumber}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="text-reg delivery-details--directo-container">
              {deliveryData.type === "Export" &&
                <>
                  <div className="delivery-details--directo-container-box">
                    Lähetused:
                    <AddDirectoDelivery inHouseConsignment={deliveryData} setInHouseConsignment={setDeliveryData} updateAddressSuggestions={updateAddressSuggestions} inputRef={inputRef} setShowMapModal={setShowMapModalDelivery} />
                  </div>
                  <div className="delivery-details--directo-container-box">
                    Müügitellimused:
                    <AddDirectoSalesOrder inHouseConsignment={deliveryData} setInHouseConsignment={setDeliveryData} updateAddressSuggestions={updateAddressSuggestions} inputRef={inputRef} setShowMapModal={setShowMapModalDelivery} />
                  </div>
                </>
              }
              {deliveryData.type === "Import" && <div className="delivery-details--directo-container-box">
                Ostutellimused:
                <AddPurchaseOrder inHouseConsignment={deliveryData} setInHouseConsignment={setDeliveryData} updateAddressSuggestions={updateAddressSuggestions} inputRef={inputRef} setShowMapModal={setShowMapModalCollection} />
              </div>}
            </div>

            <div className="text-reg">
              Kirjeldus:
              <textarea className="input-addressForm" style={{ height: '65px', fontFamily: "Helvetica-Roman" }} name="internalComment" value={deliveryData.internalComment || ''} onChange={(e: any) => handleDynamicChange(e)} />
            </div>

            <div className="flex gap-xs text-reg">
              <div className="w-50">
                Projekt:
                <input className="input-addressForm" name="deliveryObject" value={deliveryData.deliveryObject || ''} onChange={(e: any) => handleDynamicChange(e)} />
              </div>
              <div className="w-50">
                Klient:
                <input className="input-addressForm" name="deliveryCompanyName" value={deliveryData.deliveryCompanyName || ''} onChange={(e: any) => handleDynamicChange(e)} />
              </div>
            </div>

            <div className="flex gap-xs text-reg">
              <div className="w-50">
                Kontakt:
                <input className="input-addressForm" name="deliveryContactName" placeholder="Kontaktisiku nimi" value={deliveryData.deliveryContactName || ''} onChange={(e: any) => handleDynamicChange(e)} />
              </div>
              <div className="w-50">
                Telefon:
                <input className="input-addressForm" name="deliveryContactPhone" placeholder="Kontaktisiku telefon" value={deliveryData.deliveryContactPhone || ''} onChange={(e: any) => handleDynamicChange(e)} />
              </div>
            </div>

            <div className="text-reg">
              Mahalaadimine:
              <input readOnly={true} className="input-addressForm" name="deliveryAddressRow1" placeholder="Sihtkoha aadress" value={deliveryData.deliveryAddressRow1 || ''} onChange={(e: any) => handleDynamicChange(e)} onClick={() => setShowMapModalDelivery(true)} />
              <SelectPlaceInputModal
                addressSuggestions={addressSuggestions}
                setAddressSuggestions={setAddressSuggestions}
                inputRef={inputRef}
                showMapModal={showMapModalDelivery}
                setShowMapModal={setShowMapModalDelivery}
                handleAddressData={handleDeliveryAddressData}
                inputAddress={deliveryData.deliveryAddressRow1}
                inputLatitude={deliveryData.deliveryLatitude}
                inputLongitude={deliveryData.deliveryLongitude}
                title="Sisesta mahalaadimise aadress" />
            </div>

            <div className="text-reg">
              Pealelaadimine:
              <input className="input-addressForm" name="collectionAddressRow1" placeholder="Pealelaadimise aadress" value={deliveryData.collectionAddressRow1 || ''} onChange={(e: any) => handleDynamicChange(e)} onClick={() => setShowMapModalCollection(true)} />
              <SelectPlaceInputModal
                addressSuggestions={addressSuggestions}
                setAddressSuggestions={setAddressSuggestions}
                inputRef={inputRef}
                showMapModal={showMapModalCollection}
                setShowMapModal={setShowMapModalCollection}
                handleAddressData={handleCollectionAddressData}
                inputAddress={deliveryData.collectionAddressRow1}
                inputLatitude={deliveryData.collectionLatitude}
                inputLongitude={deliveryData.collectionLongitude}
                title="Sisesta pealelaadimise aadress" />
            </div>

            <div className="flex gap-xs text-reg">
              <div className="w-50">
                Müügiagent
                <input className="input-addressForm" name="salesPerson" placeholder="Müügiagendi nimi" value={deliveryData.salesPerson || ''} onChange={(e: any) => handleDynamicChange(e)} />
              </div>
              <div className="w-50">
                Telefon:
                <input className="input-addressForm" name="salesPersonPhone" placeholder="Müügiagendi telefon" value={deliveryData.salesPersonPhone || ''} onChange={(e: any) => handleDynamicChange(e)} />
              </div>
            </div>
            <div className="flex gap-xs text-reg">
              <div style={{ width: "33%" }}>
                Veotellimuse looja
                <input className="input-addressForm" readOnly={true} name="consignmentCreator" placeholder="Looja nimi" value={deliveryData.consignmentCreator || ''} />
              </div>
              <div style={{ width: "33%" }}>
                Telefon:
                <input className="input-addressForm" readOnly={true} name="consignmentCreatorPhone" placeholder="Looja telefon" value={deliveryData.consignmentCreatorPhone || ''} />
              </div>
              <div style={{ width: "33%" }}>
                Email:
                <input className="input-addressForm" readOnly={true} name="consignmentCreatorEmail" placeholder="Looja email" value={deliveryData.consignmentCreatorEmail || ''} />
              </div>
            </div>
            {deliveryData.consignmentDate &&
              <div className="flex gap-xs text-reg">
                <div style={{ width: "33%" }}>
                  Loomise aeg
                  <div className="input-addressForm"> {formattedDate} {formattedTime}</div>
                </div>
              </div>
            }
            {deliveryData.cancellationReason &&
              <div className="text-reg">
                Tühistamise põhjus:
                <textarea readOnly={true} className="input-addressForm" style={{ height: '65px', fontFamily: "Helvetica-Roman" }} name="cancellationReason" value={deliveryData.cancellationReason || ''} onChange={(e: any) => handleDynamicChange(e)} />
              </div>
            }
          </div>
        </div>

        {width >= 1080 &&
          <div className="delivery-details--map">
            {(deliveryData.type === 'Export' && deliveryData.deliveryLatitude !== 0 && deliveryData.deliveryLongitude !== 0) &&
              <TrackingDeliveryDriver
                driverId={deliveryData.deliveryDriverId}
                fetchDeliveryDriverLocation={fetchDeliveryDriverLocation}
                deliveryDriverLocation={deliveryDriverLocation}
                setDeliveryDriverLocation={setDeliveryDriverLocation}
                deliveryLocation={deliveryLocation}
                setDeliveryLocation={setDeliveryLocation}
                height={screenHeight}
                width={mapWidth} />
            }
            {(deliveryData.type === 'Import' && deliveryData.collectionLatitude !== 0 && deliveryData.collectionLongitude !== 0) &&
              <TrackingDeliveryDriver
                driverId={deliveryData.deliveryDriverId}
                fetchDeliveryDriverLocation={fetchDeliveryDriverLocation}
                deliveryDriverLocation={deliveryDriverLocation}
                setDeliveryDriverLocation={setDeliveryDriverLocation}
                deliveryLocation={collectionLoaction}
                setDeliveryLocation={setCollectionLocation}
                height={screenHeight}
                width={mapWidth} />
            }
          </div>
        }
      </div>
    </div>
  );
};

export default DeliveryDetails;
