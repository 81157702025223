import React, { useCallback } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import authqueries, { instance } from "../../queries/auth";
import Loading from "../common/Loading";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { UpdateDeliveryDriverLocation } from "../../queries/deliveryDriverQueries";

interface props {
    logged: boolean,
    setLogged: React.Dispatch<React.SetStateAction<boolean>>,
    logout: () => void,
    login: (guid: string) => void,
    permissions: string[],
    isFinnishUserPermitted: () => boolean,
    isSwedishUserPermitted: () => boolean,
    isAdmin: () => boolean,
    navigateUser: (userPermissions: string[], id?: string) => void
}

interface RefreshTokenResponse {
    token: string;
    newPermissions: string[];
}

export const CustomerContext = createContext({} as props);

export const useCustomer = () => useContext(CustomerContext);

export const CustomerWrapper = ({ children }: any) => {
    const [logged, setLogged] = useState(true);
    const [working, setWorking] = useState(true);
    const [permissions, setPermissions] = useState<string[]>([]);
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const login = async (guid: string) => {
        try {
            var response = await authqueries.login(guid);
            instance.defaults.headers.common["Authorization"] = "Bearer " + response.data.token
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem(("user"), response.data.fullName)
            localStorage.setItem(("email"), response.data.email)
            localStorage.setItem(("accessToken"), response.data.token)
            if (response.data.id) {
                localStorage.setItem("deliveryDriverId", response.data.id);
            } else {
                localStorage.removeItem("deliveryDriverId");
            }
            i18n.changeLanguage(response.data.language);
            localStorage.setItem("i18nextLng", response.data.language)
            localStorage.setItem("permissions", response.data.permissions)
            setPermissions(response.data.permissions)
            setLogged(true);

            navigateUser(response.data.permissions, response.data.id)
        }
        catch (err: any) {
            console.log(err.response.data)
            if (err.response.data === "Login_UserNotFound") {
                alert("You are not authorized to use this application");
            }
            setLogged(false);
            navigate("/login")
        }
        finally {
            setWorking(false)
        }
    }

    const refreshToken = useCallback(async (): Promise<RefreshTokenResponse> => {
        try {
            var encodedRefreshToken = encodeURIComponent(localStorage.getItem("refreshToken")!);
            var encodedAccessToken = encodeURIComponent(localStorage.getItem("accessToken")!);
            const response = await authqueries.refresh(encodedAccessToken, encodedRefreshToken);

            instance.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
            localStorage.setItem("refreshToken", response.data.newRefreshToken);
            localStorage.setItem("accessToken", response.data.token);
            localStorage.setItem("permissions", response.data.permissions)
            setPermissions(response.data.permissions)
            setLogged(true);

            return {
                token: response.data.token,
                newPermissions: response.data.permissions
            };
        }
        catch {
            localStorage.clear();
            sessionStorage.clear();
            setLogged(false)
        }
        finally {
            setWorking(false)
        }

        return {
            token: "",
            newPermissions: [""]
        };
    }, []); // Pass empty dependency array to memoize

    const logout = () => {
        authqueries.logout().finally(() => {
            setLogged(false);
            navigate("/login");
            localStorage.removeItem("user");
            localStorage.removeItem("email");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("deliveryDriverId");
            delete instance.defaults.headers.common["Authorization"];
        });
    };

    const navigateUser = (userPermissions: string[], id?: string) => {
        const isTransportCompanyUser = userPermissions.find((userPermissions) => userPermissions === 'PTransportCompanyUser') !== undefined;
        const isAdmin = userPermissions.find((userPermissions) => userPermissions === 'PManageUsers') !== undefined;
        const isEstonianUser = userPermissions.find((userPermissions) => userPermissions === 'PEstonianUser') !== undefined;
        const isFinnishUser = userPermissions.find((userPermissions) => userPermissions === 'PFinnishUser') !== undefined;
        const isFinnishTv = userPermissions.find((userPermissions) => userPermissions === 'PFinnishTvViewer') !== undefined;
        const isSwedishUser = userPermissions.find((userPermissions) => userPermissions === 'PSwedishUser') !== undefined;
        const isInHouserDriver = userPermissions.find((userPermissions) => userPermissions === 'PInHouseDriver') !== undefined;
        const isInHouserDriverManager = userPermissions.find((userPermissions) => userPermissions === 'PInHouseDriverManager') !== undefined;
        const isEstWarehouseWorker = userPermissions.find((userPermissions) => userPermissions === 'PEstonianWareHouseWorker') !== undefined;

        if (isTransportCompanyUser && !isAdmin) {
            navigate("/tv")
            return;
        }
        if (isFinnishUser && !isAdmin) {
            navigate("/overview")
            return;
        }

        if (isSwedishUser && !isAdmin) {
            navigate("/sweden/transport-orders")
            return;
        }

        if (isEstonianUser) {
            navigate("/")
            return;
        }
        if (isFinnishTv) {
            navigate("/Tv")
            return;
        }
        if (isInHouserDriverManager) {
            navigate(`/delivery`)
            return;
        }
        if (isInHouserDriver) {
            navigate(`/carrier-driver/${id}`)
            return;
        }
        if (isEstWarehouseWorker) {
            navigate(`/loader`)
            return;
        }
        navigate("/")
        return;
    }

    const isFinnishUserPermitted = () => {
        if (permissions.includes("PFinnishUser")) {
            return true;
        }
        return false;
    }

    const isSwedishUserPermitted = () => {
        if (permissions.includes("PSwedishUser")) {
            return true;
        }
        if (permissions.includes("PManageUsers")) {
            return true;
        }
        return false;
    }

    const isAdmin = () => {
        if (permissions.includes("PManageUsers")) {
            return true;
        }
        return false;
    }

    const requestGeolocationPermission = async () => {
        try {
            const result = await navigator.permissions.query({ name: 'geolocation' });
            if (result.state === 'granted') {
                // Geolocation permission already granted
                return;
            }
            if (result.state === 'prompt') {
                // You may want to show a UI element to prompt the user to grant permission.
                console.log('Geolocation permission prompt');
            } else {
                console.error('Geolocation permission denied');
            }
        } catch (error) {
            console.error('Error checking geolocation permission:', error);
        }
    };

    const requestArray: any[] = [];
    useEffect(() => {
        instance.interceptors.response.use(
            (response) => {
                if (requestArray.length !== 0) {
                    requestArray.forEach((x, i) => {
                        if (response.config.url === x.url) {
                            requestArray.splice(i, 1)
                        }
                    })
                }
                return response;
            },
            async (error: any) => {
                const originalRequest = error.config;
                requestArray.push(originalRequest);

                if (error.response!.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const { token, newPermissions } = await refreshToken();
                    setPermissions(newPermissions)

                    if (requestArray.length !== 0) {
                        requestArray.forEach(x => {
                            x.headers['Authorization'] = "Bearer " + token;
                        })
                    }
                    return instance(originalRequest)
                }
                return Promise.reject(error)
            })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        refreshToken()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1])); // decode JWT payload
            const expirationTime = decodedToken.exp * 1000; // JWT expiration is in seconds, convert to milliseconds

            /* console.log("Token expiration in seconds:", decodedToken.exp);
            console.log("Current time in ms:", Date.now());
            console.log("Token expiration time in ms:", expirationTime); */


            const timeToRefresh = expirationTime - Date.now() - (60 * 1000); // 1minute
            //console.log("Time to refresh in s:", timeToRefresh / 1000);

            if (timeToRefresh > 0) {
                const refreshTimer = setTimeout(() => {
                    console.log("Refreshing token before expiration");
                    refreshToken();
                }, timeToRefresh);

                // Cleanup timer when component unmounts or token changes
                return () => clearTimeout(refreshTimer);
            } else if (Date.now() > expirationTime) {
                // Handle the case where the token is already expired
                console.log("Token is already expired");
                refreshToken(); // Refresh immediately if expired
            }
        }
    }, [refreshToken]);

    useEffect(() => {
        const fetchLocation = () => {
            const driverId = localStorage.getItem("deliveryDriverId");
            if (!driverId) {
                console.log("No deliveryDriverId found in localStorage");
                return;
            }
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    UpdateDeliveryDriverLocation(+driverId, position.coords.latitude, position.coords.longitude)
                        .catch(err => console.error("Failed to update location:", err));
                },
                (error) => {
                    console.error("Geolocation error:", error);
                }
            );
        };

        const driverId = localStorage.getItem("deliveryDriverId");
        if (driverId && driverId !== "5" && driverId !== "3") {
            requestGeolocationPermission();
            fetchLocation();

            const interval = setInterval(fetchLocation, 1 * 60 * 1000); // 1 minute
            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    return (
        <CustomerContext.Provider value={{ setLogged, logged, logout, login, permissions, isFinnishUserPermitted, isAdmin, isSwedishUserPermitted, navigateUser }}>
            {working ? <Loading /> : children}
        </CustomerContext.Provider>
    );
};